<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    role="presentation"
    :class="[
      'icon',
      colour !== '' ? 'icon--' + colour : ''
    ]"
  >
    <IconBase :icon="name" />
  </svg>
</template>

<script>
import IconBase from './iconBase'

export default {
  name: 'SvgIcon',
  components: {
    IconBase
  },
  props: {
    /**
     * name is the name of the icon.
     *
     * @param {String}
     */
    name: {
      type: String,
      default: 'apollo'
    },
    /**
     * width is the svg width setting.
     *
     * @param {Number, String}
     */
    width: {
      type: [Number, String],
      default: 40
    },
    /**
     * height is the svg height setting.
     *
     * @param {Number, String}
     */
    height: {
      type: [Number, String],
      default: 40
    },
    /**
     * colour is the icon colour class name.
     *
     * @param {String}
     */
    colour: {
      type: [String],
      default: ''
    }
  },

  computed: {
    viewbox () {
      const vb = {
        apollo: '0 0 600 600',
        rocket: '0 0 600 600',
        'apollo-logo': '0 0 650 650'
      }
      return vb[this.name]
    }
  }
}
</script>

<style lang="scss">
@import '../../../scss/init';
svg {
  display: inline-block;
  vertical-align: baseline;
  // margin-bottom: -2px; /* yes, I'm that particular about formatting */
  * {
    height: auto;
    width: 100%;
  }
}

svg.icon {
  &--black {
    fill: $color-black;
  }

  &--white {
    fill: $color-white;
  }

  &--grey {
    fill: $color-grey;
  }

  &--dark-grey {
    fill: $color-grey-dark;
  }

  // &--red {
  //   fill: $colour-red-bright;
  // }

  // &--blue {
  //   fill: $colour-blue;
  // }

  // &--orange {
  //   fill: $colour-orange;
  // }

  // &--green {
  //   fill: $colour-green;
  // }

  // vars
  // &--admin-overview {
  //   height: 80%;
  //   width: 80%;
  // }

  // &--admin-slim {
  //   height: 50px;
  //   width: 50px;
  // }

  // &--main-logo {
  //   @include animate(all, $header-animation-timing);
  //   display: block;
  //   position: fixed;
  //   top: calc(50% - 100px);

  //   .active & {
  //     height: 200px;
  //     width: 300px;
  //   }

  //   .moving & {
  //     height: 100px;
  //     top: 5px;
  //     width: 150px;
  //   }
  // }

  // &-animate-1s {
  //   @include animate(all, $header-animation-timing);
  // }
}
</style>
