import {
  api,
  // remote
} from './api.config'
import forEach from 'lodash/forEach'

export const getData = (url) => api(url)

export const postData = (url, dataArr) => {
  const data = new FormData()

  forEach(dataArr, (item, key) => {
    data.append(key, item)
  })

  return api.post(url, data)
}

export const postDataObj = (url, dataArr) => {
  const data = new FormData()

  forEach(dataArr, (item, key) => {
    data.append(key, item)
  })

  return api.post(url, data)
}

export const sortIds = (data) => {
  const resource = JSON.parse(JSON.stringify(data))
  const result = []

  forEach(resource, (item) => {
    result.push(item.id)
  })

  const response = {
    id: 'ids',
    model: result.join(',')
  }

  return response
}
