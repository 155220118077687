<template>
  <v-breadcrumbs
    v-if="crumbs && crumbs.length > 0"
    :items="crumbs"
    divider=">"
    class="px-4 py-2 mb-n2 jigsaw-breadcrumb"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
        :exact="true"
      >
        <span
          v-text="item.text"
        />
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import forEach from 'lodash/forEach'

export default {
  name: 'BreadCrumbs',

  data () {
    return {
      crumbs: []
    }
  },

  created () {
    this.$store.watch(
      (state, getters) => getters.getBreadcrumbs, (result) => {
        if (result) {
          this.createCrumbs(result)
        }
      }, {
        immediate: true
      }
    )
  },

  methods: {
    createCrumbs (crumbs) {
      if (crumbs && crumbs.length > 0) {
        const newCrumbs = []
        forEach(crumbs, (crumb, index) => {
          const newCrumb = {
            text: crumb.text,
            to: '',
            disabled: crumbs.length - 1 === index,
            link: crumbs.length - 1 !== index
          }

          if (crumb.name) {
            newCrumb.to = { name: crumb.name }
          }

          newCrumbs.push(newCrumb)
        })

        this.crumbs = newCrumbs
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.jigsaw-breadcrumb {
  position: relative;
  z-index: 2;
}
</style>
