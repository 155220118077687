<template>
  <div class="d-inline-block">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-2 d-none d-sm-inline-flex"
          @click="gotoPath(level < 3 ? 'Tickets' : 'AdminTickets' )"
        >
          <v-badge
            :color="core.colour.ticket"
            overlap
            :content="userBase.tickets.length"
            :value="userBase.tickets.length"
          >
            <v-icon v-text="core.icon.ticket" />
          </v-badge>
        </v-btn>
      </template>
      <span v-text="'Tickets'" />
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-2 d-none d-sm-inline-flex"
          @click="gotoPath(level < 3 ? 'Requests' : 'AdminRequests' )"
        >
          <v-badge
            :color="core.colour.request"
            overlap
            :content="userBase.requests.length"
            :value="userBase.requests.length"
          >
            <v-icon v-text="core.icon.request" />
          </v-badge>
        </v-btn>
      </template>
      <span v-text="'Requests'" />
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-2 d-none d-sm-inline-flex"
          @click="gotoPath(level < 3 ? 'Quotes' : 'AdminQuotes' )"
        >
          <v-badge
            :color="core.colour.quote"
            overlap
            :content="userBase.quotes.length"
            :value="userBase.quotes.length"
          >
            <v-icon v-text="core.icon.quote" />
          </v-badge>
        </v-btn>
      </template>
      <span v-text="'Quotes'" />
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-2 d-none d-sm-inline-flex"
          @click="gotoPath(level < 3 ? 'Invoices' : 'AdminInvoices' )"
        >
          <v-badge
            :color="core.colour.invoice"
            overlap
            :content="userBase.invoices.length"
            :value="userBase.invoices.length"
          >
            <v-icon v-text="core.icon.invoice" />
          </v-badge>
        </v-btn>
      </template>
      <span v-text="'Invoices'" />
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-2"
          @click="gotoPath('Messages')"
        >
          <v-badge
            :color="core.colour.messages"
            overlap
            :content="userBase.messages.length"
            :value="userBase.messages.length"
          >
            <v-icon v-text="core.icon.messages" />
          </v-badge>
        </v-btn>
      </template>
      <span v-text="'Messages'" />
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-2"
          @click="gotoPath('Profile')"
        >
          <v-icon v-text="core.icon.profile" />
        </v-btn>
      </template>
      <span v-text="'Profile'" />
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
  data: () => ({
    core: false,
    level: 0,
    userBase: {
      account: false,
      tickets: [],
      requests: [],
      quotes: [],
      invoices: []
    },
  }),
  created () {
    this.$store.watch(
      (state, getters) => getters.getUser, (result) => {
        if (result && result.id) {
          this.level = parseInt(result.acl)
        }
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getCore, (result) => {
        this.core = result;
      },
      {
        immediate: true,
      }
    )

    this.$store.watch(
      (state, getters) => getters.getUserBase, (result) => {
        this.userBase = result
      }, {
        immediate: true
      }
    )
  },
  methods: {
    gotoPath (name) {
      if (name !== '') {
        if (this.$route.name !== name) {
          this.$router.push({ name: name })
        }
      }
    }
  }
}
</script>
