<template>
  <v-app v-if="loggedIn">
    <v-app-bar
      height="64px"
      class="apollo-header"
      fixed
      app
    >
      <v-btn icon color="" left @click="toggleDrawer()">
        <v-icon
          v-text="drawer ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"
        />
      </v-btn>

      <v-toolbar-title class="pl-2" v-text="headerTitle" />

      <v-spacer />

      <TopBar />

    </v-app-bar>

    <NavDrawer />

    <v-main class="apollo apollo-overlay">
      <div class="apollo-wrapper" id="scrolling-root">
        <Breadcrumb />
        <router-view />
      </div>
    </v-main>
    <v-footer
      app
      class="jigsaw-footer footer-broder"
    >
      <v-spacer />
      <Time />
      <v-spacer />
      <div class="footer-logo d-flex align-center">
        Powered by <Icon name="apollo-logo" height="40" width="40" />
      </div>
    </v-footer>
    <v-snackbar
      app
      top
    >
      HELLO
    </v-snackbar>
  </v-app>
  <v-app v-else>
    <Login v-if="loaded && $route.name !== 'ResetPassword'" />
    <ResetPassword v-else-if="loaded" />
  </v-app>
</template>

<script>
import { getData } from '@/utils/api/api'
import Breadcrumb from '@/components/breadcrumb/breadcrumb'
import Icon from '@/components/icon/icon'
import Login from '@/views/login/login'
import ResetPassword from '@/views/reset-password/reset-password'
import Time from '@/components/time/time'
import TopBar from './partials/top-nav'
import NavDrawer from './partials/menu'
export default {
  name: 'App',
  components: {
    Breadcrumb,
    Icon,
    Login,
    ResetPassword,
    Time,
    TopBar,
    NavDrawer
  },

  data: () => ({
    //
    loggedIn: false,
    loaded: false,
    core: false,
    user: {},
    userBase: {
      account: false,
      tickets: [],
      requests: [],
      quotes: [],
      invoices: []
    },
    level: 0,
    drawer: true,
    //
    headerTitle: 'Dashboard',
    mini: true
  }),
  watch: {
    $route (to, from) {
      // react to route changes...
      if (to.name !== from.name) {
        this.$store.commit('setNavDrawer', true)
        this.$store.commit('setBreadcrumbs', to.meta.breadcrumbs)
        this.$store.commit('updateBase', true)
      }

      // if (to !== from) {

      //   // this.help = false
      //   // this.getHelp()
      //   this.mini = true
      // }
    }
  },
  created () {
    this.$store.commit('setBreadcrumbs', this.$route.meta.breadcrumbs)
    this.$store.watch(
      (state, getters) => getters.getUser, (result) => {
        if (result && result.id) {
          this.user = result
          this.level = parseInt(result.acl)
          this.loggedIn = true
        } else {
          this.user = {}
          this.loggedIn = false
        }
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getCore, (result) => {
        this.core = result;
      },
      {
        immediate: true,
      }
    )

    this.$store.watch(
      (state, getters) => getters.getNavDrawer, (result) => {
        this.drawer = result;
      },
      {
        immediate: true,
      }
    )

    getData('users/getUser').then(response => {
      if (response.data && response.data.user) {
        if (response.data.user) {
          this.$store.commit('setUser', response.data.user)
        }
      } else {
        if (this.$route.path !== '/login' && this.$route.name !== 'ResetPassword') {
          this.$router.push('/login')
        }
      }

      setTimeout(() => {
        this.loaded = true
      }, 1)
    })

    this.$store.watch(
      (state, getters) => getters.getHeaderTitle, (result) => {
        this.headerTitle = result
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getUserBase, (result) => {
        this.userBase = result
      }, {
        immediate: true
      }
    )
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
      this.$store.commit('setNavDrawer', this.drawer)
    },
    logout () {
      getData('user/logout').then(res => {
        if (res) {
          this.$store.commit('setUser', {})
          // this.$store.commit('setAccount', {})
          this.user = {}
          // this.userTitle = false
          // this.userLevel = false
          this.loggedIn = false
          this.$cookies.remove('apolloSystemUser')

          if (this.$route.path !== '/login') {
            this.$router.push('/login')
          }
        }
      })
    },
    gotoPath (name) {
      if (name !== '') {
        if (this.$route.name !== name) {
          this.$router.push({ name: name })
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import '../scss/styles';
</style>
