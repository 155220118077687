import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/dashboard/dashboard.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/dashboard/dashboard.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard' },
      ]
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: '/password-reset/:key',
    name: 'ResetPassword',
    component: () => import('@/views/reset-password/reset-password.vue'),
    meta: {
      breadcrumbs: []
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/user/profile.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Profile' }
      ]
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/account/account'),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account' }
      ]
    }
  },
  // Invoices
  {
    path: "/account/invoices",
    name: "Invoices",
    component: () => import("@/views/invoices/invoices.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Invoices' }
      ]
    },
  },
  {
    path: "/account/invoices/invoice/:id",
    name: "Invoice",
    component: () => import("@/views/invoices/invoice.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Invoices', name: 'Invoices' },
        { text: 'Invoice' }
      ]
    },
  },
  // Quotes
  {
    path: "/account/quotes",
    name: "Quotes",
    component: () => import("@/views/quotes/quotes.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Quotes' }
      ]
    },
  },
  {
    path: "/account/quotes/quote/:id",
    name: "Quote",
    component: () => import("@/views/quotes/quote.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Quotes', name: 'Quotes' },
        { text: 'Quote' }
      ]
    },
  },
  // Requests
  {
    path: "/account/requests",
    name: "Requests",
    component: () => import("@/views/requests/requests.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Requests' }
      ]
    },
  },
  {
    path: "/account/requests/request/:id",
    name: "Request",
    component: () => import("@/views/requests/request.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Requests', name: 'Requests' },
        { text: 'Request' }
      ]
    },
  },
  // Tickets
  {
    path: "/account/tickets",
    name: "Tickets",
    component: () => import("@/views/tickets/tickets.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Tickets' }
      ]
    },
  },
  {
    path: "/account/tickets/ticket/:id",
    name: "Ticket",
    component: () => import("@/views/tickets/ticket.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Tickets', name: 'Tickets' },
        { text: 'Ticket' }
      ]
    },
  },
  // Messages
  {
    path: "/messages",
    name: "Messages",
    component: () => import("@/views/messages/messages.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Messages' }
      ]
    },
  },
  // User
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: () => import('@/views/admin/users/users'),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Users' }
      ]
    }
  },
  {
    path: '/admin/user/:id',
    name: 'AdminUser',
    component: () => import('@/views/admin/users/user'),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Users', name: 'AdminUsers' },
        { text: 'User' }
      ]
    }
  },
  // Supports
  {
    path: '/account/supports',
    name: 'Supports',
    component: () => import('@/views/support/supports'),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Supports' },
      ]
    }
  },
  {
    path: '/account/supports/support/:id',
    name: 'Support',
    component: () => import('@/views/support/support'),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Account', name: 'Account' },
        { text: 'Supports', name: 'Supports' },
        { text: 'Support' }
      ]
    }
  },
  ////////
  // Admin
  ////////
  {
    path: "/admin/accounts",
    name: "AdminAccounts",
    component: () => import("@/views/admin/accounts/accounts.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Accounts' }
      ]
    },
  },
  {
    path: "/admin/account/:id",
    name: "AdminAccount",
    component: () => import("@/views/admin/accounts/account.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Accounts', name: 'AdminAccounts' },
        { text: 'Admin Account' }
      ]
    },
  },
  {
    path: "/admin/invoices",
    name: "AdminInvoices",
    component: () => import("@/views/admin/invoices/invoices.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Invoices' }
      ]
    },
  },
  {
    path: "/admin/invoice/:id",
    name: "AdminInvoice",
    component: () => import("@/views/admin/invoices/invoice.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Invoices', name: 'AdminInvoices' },
        { text: 'Admin Invoice' }
      ]
    },
  },
  {
    path: "/admin/quotes",
    name: "AdminQuotes",
    component: () => import("@/views/admin/quotes/quotes.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Quotes' }
      ]
    },
  },
  {
    path: "/admin/quote/:id",
    name: "AdminQuote",
    component: () => import("@/views/admin/quotes/quote.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Quotes', name: 'AdminQuotes' },
        { text: 'Admin Quote' }
      ]
    },
  },
  {
    path: "/admin/requests",
    name: "AdminRequests",
    component: () => import("@/views/admin/requests/requests.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Requests' }
      ]
    },
  },
  {
    path: "/admin/request/:id",
    name: "AdminRequest",
    component: () => import("@/views/admin/requests/request.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Requests', name: 'AdminRequests' },
        { text: 'Admin Request' }
      ]
    },
  },
  // Support
  {
    path: "/admin/support",
    name: "AdminSupportOverview",
    component: () => import("@/views/admin/support/overview.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Support Overview' }
      ]
    },
  },
  {
    path: '/admin/support/:id',
    name: 'AdminSupport',
    component: () => import('@/views/admin/support/support.vue'),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Support Overview', name: 'AdminSupportOverview' },
        { text: 'Admin Support' }
      ]
    }
  },
  // Tasks
  {
    path: "/admin/tasks",
    name: "AdminTasks",
    component: () => import("@/views/admin/tasks/tasks.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Tasks' }
      ]
    },
  },
  {
    path: "/admin/task/:id",
    name: "AdminTask",
    component: () => import("@/views/admin/tasks/task.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Tasks', name: 'AdminTasks' },
        { text: 'Admin Task' }
      ]
    },
  },
  {
    path: "/admin/tickets",
    name: "AdminTickets",
    component: () => import("@/views/admin/tickets/tickets.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Tickets' }
      ]
    },
  },
  {
    path: "/admin/ticket/:id",
    name: "AdminTicket",
    component: () => import("@/views/admin/tickets/ticket.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin Tickets', name: 'AdminTickets' },
        { text: 'Admin Ticket' }
      ]
    },
  },
  // SYSTEM
  {
    path: "/admin/system",
    name: "AdminSystem",
    component: () => import("@/views/admin/system/system.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin System' }
      ]
    },
  },
  {
    path: "/admin/system/types",
    name: "AdminTypes",
    component: () => import("@/views/admin/types/types.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin System', name: 'AdminSystem' },
        { text: 'Admin Types' }
      ]
    },
  },
  {
    path: "/admin/system/tags",
    name: "AdminTags",
    component: () => import("@/views/admin/tags/tags.vue"),
    meta: {
      breadcrumbs: [
        { text: 'Dashboard', name: 'Dashboard' },
        { text: 'Admin System', name: 'AdminSystem' },
        { text: 'Admin Tags' }
      ]
    },
  },
  // Apollo
  // API Keys
  {
    path: '/admin/apollo/keys',
    name: 'ApolloAPIs',
    component: () => import('@/views/admin/apollo/keys'),
    meta: {
      breadcrumbs: [
        {
          text: 'Dashboard',
          name: 'Dashboard'
        },
        {
          text: 'Apollo API Keys'
        }
      ]
    }
  },
  // Modules
  {
    path: '/admin/apollo/modules',
    name: 'ApolloModules',
    component: () => import('@/views/admin/apollo/modules'),
    meta: {
      breadcrumbs: [
        {
          text: 'Dashboard',
          name: 'Dashboard'
        },
        {
          text: 'Apollo Modules'
        }
      ]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
