<template>
  <v-navigation-drawer
    v-if="core"
    permanent
    :mini-variant="drawer"
    app
    class="site-main-nav"
  >
    <v-list
      v-if="siteNav.length > 0"
      nav
      dense
      class="full-height"
    >
      <v-list-item-group active-class="blue-purple--text text--accent-4">
        <v-list-item
          v-for="(item, key) in siteNav"
          :key="'single_' + key"
          link
          :to="{name: item.component}"
          exact
        >
          <v-tooltip
            v-if="drawer"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon
                v-if="item.icon"
                v-bind="attrs"
                v-on="on"
                class="d-flex align-center jstify-center"
              >
                <v-icon
                  light
                  :color="item.color ? item.color : 'grey darken-1'"
                  v-text="item.icon"
                />
              </v-list-item-icon>
            </template>
            <span v-text="item.title" />
          </v-tooltip>
          <v-list-item-icon
            v-else
            class="d-flex align-center jstify-center"
          >
            <v-icon
              light
              :color="item.color ? item.color : 'grey darken-1'"
              v-text="item.icon"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span
                class="text-capitalize"
                v-text="item.title"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <v-divider />
      <v-list>
        <v-list-item @click="logout()" link>
          <v-tooltip
            v-if="drawer"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-power-off</v-icon>
              </v-list-item-icon>
            </template>
          <span v-text="'Log Out'" />
          </v-tooltip>
          <v-list-item-icon v-else>
            <v-icon>fas fa-power-off</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { getData } from '@/utils/api/api'
export default {
  name: 'MainMenu',
  data: () => ({
    drawer: true,
    group: null,
    core: false,
    //
    level: 0,
    siteNav: []
  }),
  computed: {
    singleItems () {
      return [
        {
          icon: this.core.icon.dashboard,
          title: 'Dashboard',
          component: 'Dashboard',
          color: this.core.colour.dashboard,
          acl: 0
        },
        {
          icon: this.core.icon.messages,
          title: 'Messages',
          component: 'Messages',
          color: this.core.colour.messages,
          acl: 0
        },
        {
          icon: this.core.icon.account,
          title: 'Account',
          component: 'Account',
          color: this.core.colour.account,
          acl: 1
        },
        {
          icon: this.core.icon.profile,
          title: 'Profile',
          component: 'Profile',
          color: this.core.colour.profile,
          acl: 0
        },
        {
          icon: this.core.icon.ticket,
          title: 'Tickets',
          component: 'Tickets',
          color: this.core.colour.ticket,
          acl: 0
        },
        {
          icon: this.core.icon.request,
          title: 'Requests',
          component: 'Requests',
          color: this.core.colour.request,
          acl: 0
        },
        {
          icon: this.core.icon.quote,
          title: 'Quotes',
          component: 'Quotes',
          color: this.core.colour.quote,
          acl: 0
        },
        {
          icon: this.core.icon.invoice,
          title: 'Invoices',
          component: 'Invoices',
          color: this.core.colour.invoice,
          acl: 0
        },
        {
          icon: this.core.icon.support,
          title: 'Support',
          component: 'Supports',
          color: this.core.colour.support,
          acl: 1
        }
      ]
    },
    adminItems () {
      return [
        {
          icon: this.core.icon.dashboard,
          title: 'Dashboard',
          component: 'Dashboard',
          color: this.core.colour.dashboard,
          acl: 0
        },
        {
          icon: this.core.icon.messages,
          title: 'Messages',
          component: 'Messages',
          color: this.core.colour.messages,
          acl: 0
        },
        {
          icon: this.core.icon.user,
          title: 'Users',
          component: 'AdminUsers',
          color: this.core.colour.user,
          acl: 3
        },
        {
          icon: this.core.icon.account,
          title: 'Accounts',
          component: 'AdminAccounts',
          color: this.core.colour.account,
          acl: 3
        },
        {
          icon: this.core.icon.invoice,
          title: 'Invoices',
          component: 'AdminInvoices',
          color: this.core.colour.invoice,
          acl: 3
        },
        {
          icon: this.core.icon.quote,
          title: 'Quotes',
          component: 'AdminQuotes',
          color: this.core.colour.quote,
          acl: 3
        },
        {
          icon: this.core.icon.ticket,
          title: 'Tickets',
          component: 'AdminTickets',
          color: this.core.colour.ticket,
          acl: 3
        },
        {
          icon: this.core.icon.request,
          title: 'Requests',
          component: 'AdminRequests',
          color: this.core.colour.request,
          acl: 3
        },
        {
          icon: this.core.icon.task,
          title: 'Tasks',
          component: 'AdminTasks',
          color: this.core.colour.task,
          acl: 3
        },
        // System
        {
          icon: this.core.icon.system,
          title: 'System',
          component: 'AdminSystem',
          color: this.core.colour.system,
          acl: 4
        },
        {
          icon: this.core.icon.type,
          title: 'Types',
          component: 'AdminTypes',
          color: this.core.colour.type,
          acl: 4
        },
        {
          icon: this.core.icon.tag,
          title: 'Tags',
          component: 'AdminTags',
          color: this.core.colour.tag,
          acl: 4
        },
        {
          icon: this.core.icon.support,
          title: 'Support',
          component: 'AdminSupportOverview',
          color: this.core.colour.support,
          acl: 4
        },
        {
          icon: this.core.icon.apollo,
          title: 'Apollo APIs',
          component: 'ApolloAPIs',
          color: this.core.colour.apollo,
          acl: 4
        },
        {
          icon: this.core.icon.apolloModule,
          title: 'Apollo Modules',
          component: 'ApolloModules',
          color: this.core.colour.apollo,
          acl: 4
        }
      ]
    }
  },
  created () {
    this.level = parseInt(this.$store.getters.getUser.acl)
    this.$store.watch(
      (state, getters) => getters.getNavDrawer, (result) => {
        this.drawer = result;
      },
      {
        immediate: true,
      }
    )

    this.$store.watch(
      (state, getters) => getters.getCore, (result) => {
        this.core = result;
      },
      {
        immediate: true,
      }
    )

    const siingleList = []

    if (this.level < 3) {
      this.singleItems.forEach(item => {
        if (this.level > item.acl && this.level < 3) {
          siingleList.push(item)
        }
      })
    }

    if (this.level > 2) {
      this.adminItems.forEach(item => {
        if (this.level >= item.acl && this.level > 2) {
          siingleList.push(item)
        }
      })
    }

    this.siteNav = siingleList
  },
  methods: {
    logout () {
      getData('users/logout').then(res => {
        if (res) {
          this.$cookies.remove('apolloSystemUser')
          this.$store.commit('setUser', {})
          this.user = {}
          this.loggedIn = false
          this.$store.commit('setNavDrawer', true)

          if (this.$route.path !== '/login') {
            this.$router.push('/login')
          }
        }
      })
    }
  }
}
</script>
