import axios from 'axios'

export const API_URL = '/api/v1/'

const api = axios.create({
  baseURL: API_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const remote = axios.create({
  baseURL: API_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export {
  api,
  remote
}
