<template>
  <v-card
    class="mt-5"
  >
    <v-card-title
      :class="[
        'px-4 pb-0',
        fullWidth ? '' : 'd-flex align-start'
      ]"
    >
      <v-sheet
        :class="[
          'overflow-hidden mt-n9',
          fullWidth ? 'full-width' : ''
        ]"
        rounded
        :elevation="elevation"
        :color="colour"
      >
        <div
          :class="[
            'pa-' + iconSpace,
            'white--text d-flex'
          ]"
        >
          <v-icon
            v-if="icon !== '' && icon !== 'apollo-logo' && icon !== 'apollo' && icon !== 'rocket'"
            :size="size"
            color="white"
            v-text="icon"
          />
          <Icon
            v-if="icon === 'apollo-logo'"
            name="apollo-logo"
            :width="size"
            :height="size"
            colour="white"
          />
          <Icon
            v-if="icon === 'apollo'"
            name="apollo"
            :width="size"
            :height="size"
            colour="white"
          />
          <Icon
            v-if="icon === 'rocket'"
            name="rocket"
            :width="size"
            :height="size"
            colour="white"
          />
          <div
            v-if="fullWidth"
            class="ml-2"
          >
            <div
              v-if="title !== ''"
              class="text-h4"
              v-text="title"
            />
            <div
              v-if="subtitle !== ''"
              class="text-h5"
              v-text="subtitle"
            />
            <div
              v-if="caption"
              class="text-caption"
              v-text="caption"
            />
          </div>
          <v-spacer />
          <slot v-if="fullWidth" name="cta" />
        </div>
        <slot name="fullcta" />
      </v-sheet>
      <div
        v-if="!fullWidth"
        class="d-inline-flex flex-sm-grow-1 pl-3"
      >
        <!-- Title Text Block -->
        <div>
          <!-- Subtitle -->
          <div
            v-if="subtitle !== ''"
            class="subtitle-2 font-weight-light line-height-1"
            v-text="subtitle"
          />
          <!-- Title Block -->
          <div
            v-if="title !== ''"
            class="d-inline-flex text-h5 font-weight-light line-height-1 align-baseline"
          >
            <span v-text="title" />
            <span v-if="titleAddition !== ''" class="text-h6 font-weight-light px-2 mt-n1" v-text="'-' + titleAddition" />
            <slot class="text-h6 font-weight-light px-2" name="titleeditor" />
          </div>
          <div
            v-if="subtitleBelow !== ''"
            class="subtitle-2 font-weight-light line-height-1 text-capitalize"
            v-text="subtitleBelow"
          />

          <!-- Caption -->
          <div
            v-if="caption"
            class="text-caption"
            v-text="caption"
          />
          <slot name="editcta" />
        </div>
        <!-- End of Title Text Block -->

        <v-spacer />
        <div class="d-sm-inline-block d-none">
          <slot name="cta" />
        </div>
      </div>

      <div class="d-sm-none d-inline-block pt-4 mr-auto">
        <slot name="cta" />
      </div>
    </v-card-title>
    <slot />
  </v-card>
</template>

<script>
import Icon from '@/components/icon/icon'
export default {
  name: 'CardFull',
  components: { Icon },
  props: {
    /**
     * name is the name of the icon.
     *
     * @param {String}
     */
    colour: {
      type: String,
      default: 'primary'
    },
    /**
     * This is the card main title.
     *
     * @param {String}
     */
    title: {
      type: String,
      default: ''
    },
    titleAddition: { type: String, default: '' },
    /**
     * This is the card  subtitle.
     *
     * @param {String}
     */
    subtitle: {
      type: String,
      default: ''
    },
    subtitleBelow: {
      type: String,
      default: ''
    },
    /**
     * This is the card main title.
     *
     * @param {String}
     */
    caption: {
      type: [String, Boolean],
      default: false
    },
    /**
     * name is the name of the icon.
     *
     * @param {String}
     */
    icon: {
      type: String,
      default: ''
    },
    /**
     * iconSize is the size of the icon large/small/x-large/x-small.
     *
     * @param {String}
     */
    iconSize: {
      type: String,
      default: 'large'
    },
    /**
     * iconSize is the size of the icon large/small/x-large/x-small.
     *
     * @param {String}
     */
    iconSpace: {
      type: String,
      default: '8'
    },
    /**
     * name is the name of the icon.
     *
     * @param {String}
     */
    elevation: {
      type: Number,
      default: 2
    },
    /**
     * name is the name of the icon.
     *
     * @param {String}
     */
    fullWidth: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    size: '36'
  }),
  created () {
    let size = ''
    if (this.iconSize !== '') {
      switch(this.iconSize) {
        case 'x-small':
          // code block
          size = '12'
          break;
        case 'small':
          // code block
          size = '16'
          break;
        case 'medium':
          // code block
          size = '24'
          break;
        case 'large':
          // code block
          size = '36'
          break;
        case 'x-large':
          // code block
          size = '40'
          break;
        default:
          // code block
          size = ''
      }

      this.size = size
    }
  }
}
</script>
