<template>
  <g v-if="icon === 'apollo'">
    <polygon fill="#4CAF50" points="350.8,459.2 368.5,270.1 524.1,387.1 "/>
    <polygon fill="#3F51B5" points="196.5,343.2 214.2,154.1 369.8,271.1 "/>
    <polygon fill="#FFC107" points="255.4,590 272.5,400.4 429.2,518.2 "/>
    <polygon fill="#FFC107" points="100.9,473.8 118,284.2 274.7,402.1 "/>
    <polygon fill="#CDDC39" points="523.7,386.8 506.6,576.4 349.9,458.6 "/>
    <polygon fill="#00BCD4" points="368.6,270.2 351.4,459.7 194.7,341.9 "/>
    <polygon fill="#673AB7" points="214.5,154.3 197.4,343.9 40.7,226.1 "/>
    <polygon fill="#F44336" points="541.1,198.8 523.9,388.4 367.2,270.5 "/>
    <polygon fill="#2196F3" points="559.3,10 541,198.7 386.5,82.6 "/>
    <polygon fill="#C31A7F" points="387,82.9 369.9,272.5 213.2,154.7 "/>
    <polygon fill="#FF5722" points="368.3,271.3 386,82.2 541.6,199.2 "/>
  </g>
  <g v-else-if="icon === 'rocket'">
    <polygon points="559.3,10 40.7,226.1 118,284.2 100.9,473.8 272.3,403.1 255.4,590 429.2,518.2 506.6,576.4 "/>
  </g>
  <g v-else-if="icon === 'apollo-logo'">
    <g id="Helvetica_Medium_Text">
      <path fill="#6C6F7A" d="M104.2,552h16.6l34.2,88.9h-16.7l-8.3-23.5H94.5l-8.3,23.5H70.1L104.2,552z M98.7,605.5h27.1l-13.3-38.2 h-0.4L98.7,605.5z"/>
      <path fill="#6C6F7A" d="M166.5,552h39.2c6.3,0,11.5,0.9,15.4,2.7c4,1.8,7.1,4.1,9.2,6.8c2.2,2.7,3.6,5.7,4.4,9 c0.8,3.2,1.2,6.2,1.2,9c0,2.7-0.4,5.7-1.2,8.9c-0.8,3.2-2.3,6.2-4.4,8.9c-2.2,2.7-5.2,5-9.2,6.8c-4,1.8-9.1,2.7-15.4,2.7h-23.7 v34.1h-15.6V552z M182.1,594.1h22.8c1.7,0,3.5-0.2,5.4-0.7c1.8-0.5,3.5-1.3,5-2.4c1.5-1.1,2.8-2.6,3.7-4.5c1-1.9,1.4-4.2,1.4-7 c0-2.9-0.4-5.3-1.2-7.2c-0.8-1.9-2-3.4-3.4-4.5c-1.4-1.1-3-1.9-4.9-2.3c-1.8-0.4-3.8-0.6-5.9-0.6h-23V594.1z"/>
      <path fill="#6C6F7A" d="M290.5,549.9c6.6,0,12.6,1.2,17.9,3.7c5.3,2.5,9.7,5.9,13.4,10.1c3.7,4.2,6.5,9.2,8.4,14.8 c1.9,5.6,2.9,11.6,2.9,17.9c0,6.4-1,12.4-2.9,18.1c-2,5.6-4.8,10.6-8.4,14.8c-3.7,4.2-8.1,7.6-13.4,10c-5.3,2.4-11.2,3.7-17.9,3.7 c-6.6,0-12.6-1.2-17.9-3.7c-5.3-2.4-9.7-5.8-13.4-10c-3.7-4.2-6.5-9.2-8.4-14.8c-2-5.6-2.9-11.7-2.9-18.1c0-6.3,1-12.3,2.9-17.9 c1.9-5.6,4.8-10.6,8.4-14.8c3.7-4.2,8.1-7.6,13.4-10.1C277.9,551.1,283.9,549.9,290.5,549.9z M290.5,562.6c-4.7,0-8.8,1-12.3,2.9 c-3.4,1.9-6.2,4.5-8.4,7.7c-2.2,3.2-3.8,6.8-4.8,10.9c-1,4.1-1.6,8.2-1.6,12.4c0,4.2,0.5,8.4,1.6,12.4c1,4.1,2.6,7.7,4.8,10.9 c2.2,3.2,5,5.7,8.4,7.7c3.4,1.9,7.5,2.9,12.3,2.9s8.8-1,12.3-2.9c3.4-1.9,6.2-4.5,8.4-7.7c2.2-3.2,3.8-6.8,4.8-10.9 c1-4.1,1.6-8.2,1.6-12.4c0-4.2-0.5-8.4-1.6-12.4c-1-4.1-2.6-7.7-4.8-10.9c-2.2-3.2-5-5.7-8.4-7.7 C299.3,563.5,295.2,562.6,290.5,562.6z"/>
      <path fill="#6C6F7A" d="M350.4,552H366v75.4h45.2v13.4h-60.7V552z"/>
      <path fill="#6C6F7A" d="M425,552h15.6v75.4h45.2v13.4H425V552z"/>
      <path fill="#6C6F7A" d="M537.4,549.9c6.6,0,12.6,1.2,17.9,3.7c5.3,2.5,9.7,5.9,13.4,10.1c3.7,4.2,6.5,9.2,8.4,14.8 c1.9,5.6,2.9,11.6,2.9,17.9c0,6.4-1,12.4-2.9,18.1c-2,5.6-4.8,10.6-8.4,14.8c-3.7,4.2-8.1,7.6-13.4,10c-5.3,2.4-11.2,3.7-17.9,3.7 c-6.6,0-12.6-1.2-17.9-3.7c-5.3-2.4-9.7-5.8-13.4-10c-3.7-4.2-6.5-9.2-8.4-14.8c-2-5.6-2.9-11.7-2.9-18.1c0-6.3,1-12.3,2.9-17.9 c1.9-5.6,4.8-10.6,8.4-14.8c3.7-4.2,8.1-7.6,13.4-10.1C524.8,551.1,530.7,549.9,537.4,549.9z M537.4,562.6c-4.7,0-8.8,1-12.3,2.9 c-3.4,1.9-6.2,4.5-8.4,7.7c-2.2,3.2-3.8,6.8-4.8,10.9c-1,4.1-1.6,8.2-1.6,12.4c0,4.2,0.5,8.4,1.6,12.4c1,4.1,2.6,7.7,4.8,10.9 c2.2,3.2,5,5.7,8.4,7.7c3.4,1.9,7.5,2.9,12.3,2.9c4.7,0,8.8-1,12.3-2.9c3.4-1.9,6.2-4.5,8.4-7.7c2.2-3.2,3.8-6.8,4.8-10.9 c1-4.1,1.6-8.2,1.6-12.4c0-4.2-0.5-8.4-1.6-12.4c-1-4.1-2.6-7.7-4.8-10.9c-2.2-3.2-5-5.7-8.4-7.7 C546.2,563.5,542.1,562.6,537.4,562.6z"/>
    </g>
    <g id="rocket">
      <polygon fill="#4CAF50" points="368.9,395.8 384.3,232.1 518.9,333.4"/>
      <polygon fill="#3F51B5" points="235.4,295.4 250.7,131.7 385.4,233"/>
      <polygon fill="#FFC107" points="286.4,509 301.2,344.9 436.9,446.9"/>
      <polygon fill="#FFC107" points="152.7,408.5 167.5,244.4 303.1,346.4"/>
      <polygon fill="#CDDC39" points="518.6,333.2 503.8,497.3 368.2,395.3"/>
      <polygon fill="#00BCD4" points="384.4,232.2 369.5,396.3 233.9,294.3"/>
      <polygon fill="#673AB7" points="251,131.9 236.2,296 100.6,194"/>
      <polygon fill="#F44336" points="533.7,170.4 518.8,334.5 383.2,232.5"/>
      <polygon fill="#2196F3" points="549.4,7 533.6,170.4 399.9,69.8"/>
      <polygon fill="#C31A7F" points="400.3,70.1 385.5,234.2 249.9,132.3"/>
      <polygon fill="#FF5722" points="384.1,233.2 399.4,69.5 534.1,170.7"/>
    </g>
  </g>
</template>
<script>
export default {
  name: 'IconBase',
  props: {
    /**
     * icon is the name of the icon.
     *
     * @param {String}
     */
    icon: {
      type: String,
      default: 'apollo'
    }
  }
}
</script>