var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 d-none d-sm-inline-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.gotoPath(_vm.level < 3 ? 'Tickets' : 'AdminTickets' )}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":_vm.core.colour.ticket,"overlap":"","content":_vm.userBase.tickets.length,"value":_vm.userBase.tickets.length}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.core.icon.ticket)}})],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Tickets')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 d-none d-sm-inline-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.gotoPath(_vm.level < 3 ? 'Requests' : 'AdminRequests' )}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":_vm.core.colour.request,"overlap":"","content":_vm.userBase.requests.length,"value":_vm.userBase.requests.length}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.core.icon.request)}})],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Requests')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 d-none d-sm-inline-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.gotoPath(_vm.level < 3 ? 'Quotes' : 'AdminQuotes' )}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":_vm.core.colour.quote,"overlap":"","content":_vm.userBase.quotes.length,"value":_vm.userBase.quotes.length}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.core.icon.quote)}})],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Quotes')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 d-none d-sm-inline-flex",attrs:{"icon":""},on:{"click":function($event){return _vm.gotoPath(_vm.level < 3 ? 'Invoices' : 'AdminInvoices' )}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":_vm.core.colour.invoice,"overlap":"","content":_vm.userBase.invoices.length,"value":_vm.userBase.invoices.length}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.core.icon.invoice)}})],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Invoices')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.gotoPath('Messages')}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":_vm.core.colour.messages,"overlap":"","content":_vm.userBase.messages.length,"value":_vm.userBase.messages.length}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.core.icon.messages)}})],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Messages')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.gotoPath('Profile')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.core.icon.profile)}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Profile')}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }