import Vue from 'vue'
import App from './App.vue'
import router from './utils/router/'
import store from './utils/store/'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import VueCookies from 'vue-cookies'
import sanitizeHTML from 'sanitize-html'

Vue.prototype.$sanitize = sanitizeHTML
Vue.use(DatetimePicker)
Vue.use(VueCookies)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
