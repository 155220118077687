<template>
  <div class="jigsaw-login">
    <v-container
      v-if="!forgotPassowrd"
      fluid
      class="fill-height"
    >
      <v-row>
        <v-col
          cols="12"
          sm="9"
          offset-sm="3"
          md="6"
          offset-md="6"
          lg="4"
          offset-lg="8"
          class="jigsaw-login__panel"
        >
          <CardFull
            :colour="core.colour.jigsaw"
            icon="rocket"
            iconSpace="7"
            iconSize="medium"
            title="Login"
            :elevation="6"
            :fullWidth="false"
            :disabled="loggingInBtnState"
          >
            <v-card-text class="pa-4">
              <v-form
                class="form"
                @submit="submitForm"
                @keyup.native.enter="submitForm"
              >
                <v-text-field
                  v-model="loginForm.email"
                  name="username"
                  label="Email"
                  type="text"
                  autocomplete="off"
                  required
                  :error-messages="emailErrors"
                  @input="$v.loginForm.email.$touch()"
                  @blur="$v.loginForm.email.$touch()"
                />
                <v-text-field
                  v-model="loginForm.password"
                  :prepend-inner-icon="core.icon.lock"
                  :append-icon="showPassword ? core.icon.view : core.icon.viewOff"
                  name="password"
                  label="Password"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="off"
                  required
                  :error-messages="passwordErrors"
                  @input="$v.loginForm.password.$touch()"
                  @blur="$v.loginForm.password.$touch()"
                  @click:append="showPassword = !showPassword"
                />
                <div class="text-center">
                  <v-btn plain @click="forgotPassowrd = true">Forgot passowrd?</v-btn>
                </div>
              </v-form>
            </v-card-text>
            <v-divider class="mx-4" />
            <v-card-actions class="pa-4">
              <v-btn
                block
                large
                dark
                color="jigsaw"
                :loading="loggingInBtnState"
                @click="submitForm"
                v-text="loginBtnLabel"
              />
            </v-card-actions>
          </CardFull>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="forgotPassowrd"
      fluid
      class="fill-height"
    >
      <v-row>
        <v-col
          cols="12"
          sm="9"
          offset-sm="3"
          md="6"
          offset-md="6"
          lg="4"
          offset-lg="8"
          class="jigsaw-login__panel"
        >
          <CardFull
            :colour="core.colour.user"
            icon="rocket"
            iconSpace="7"
            iconSize="medium"
            title="Forgot Password"
            :elevation="6"
            :fullWidth="false"
          >
            <v-card-text class="pa-4">
              <v-form
                class="form"
                @submit="submitResetForm"
                @keyup.native.enter="submitResetForm"
              >
                <v-text-field
                  v-model="forgotForm.email"
                  name="username"
                  label="Email"
                  type="text"
                  autocomplete="off"
                  :disabled="resetBtnState"
                  required
                  :error-messages="forgotEmailErrors"
                  @input="$v.forgotForm.email.$touch()"
                  @blur="$v.forgotForm.email.$touch()"
                />
              </v-form>
              <div v-if="resetMessage" class="pt-4" v-text="resetMessage" />
            </v-card-text>
            <v-divider class="mx-4" />
            <v-card-actions class="pa-4">
              <v-row v-if="!resetMessage">
                <v-col
                  cols="12"
                  sm="6"
                  class="pr-sm-2"
                >
                  <v-btn
                    color="cancelBtn"
                    large
                    block
                    :disabled="resetBtnState"
                    @click="cancelResetForm"
                    v-text="'Cancel'"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pl-sm-2"
                >
                  <v-btn
                    large
                    block
                    dark
                    color="jigsaw"
                    :loading="resetBtnState"
                    :disabled="resetBtnState"
                    @click="submitResetForm"
                    v-text="'Request Reset'"
                  />
                </v-col>
              </v-row>
              <v-row v-if="resetMessage">
                <v-col cols="12">
                  <v-btn
                    large
                    block
                    dark
                    color="jigsaw"
                    @click="returnToLogin()"
                    v-text="'Bake to login'"
                  />
                </v-col>
              </v-row>
            </v-card-actions>
          </CardFull>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { postData } from '@/utils/api/api'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import CardFull from '@/components/cards/card-full'
export default {
  name: 'UserLogin',
  components: { CardFull },
  mixins: [validationMixin],

  validations: {
    loginForm: {
      password: { required },
      email: { required, email }
    },
    forgotForm: {
      email: { required, email }
    }
  },
  data: () =>({
    core: false,
    showPassword: false,
    loginForm: {
      email: '',
      password: ''
    },
    loggingInBtnState: false,
    loginBtnLabel: 'Login',
    //
    forgotPassowrd: false,
    resetBtnState: false,
    forgotForm: {
      email: ''
    },
    resetMessage: false,
    loginErrorMessage: ''
  }),

  created () {
    this.$store.watch(
      (state, getters) => getters.getCore, (result) => {
        this.core = result
      }, {
        immediate: true
      }
    )
  },

  computed: {
    passwordErrors () {
      const errors = []
      if (!this.$v.loginForm.password.$dirty) return errors
      !this.$v.loginForm.password.required && errors.push('Your password is required')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.loginForm.email.$dirty) return errors
      !this.$v.loginForm.email.email && errors.push('Must be valid e-mail')
      !this.$v.loginForm.email.required && errors.push('Your email is required')
      return errors
    },
    forgotEmailErrors () {
      const errors = []
      if (!this.$v.forgotForm.email.$dirty) return errors
      !this.$v.forgotForm.email.email && errors.push('Must be valid e-mail')
      !this.$v.forgotForm.email.required && errors.push('Your email is required')
      return errors
    }
  },

  methods: {
    submitForm (evt) {
      evt.preventDefault()
      this.loggingInBtnState = true

      postData('users/login', this.loginForm).then(response => {
        if (response.data) {
          if (response.data.user) {
            this.$store.commit('setUser', response.data.user)

            if (this.$route.path !== '/') {
              this.$router.push('/dashboard')
            }
          } else if (response.data.error) {
            console.error(response.data.error)
            this.loginErrorMessage = response.data.error
          }
        }
      })
    },
    cancelResetForm () {
      this.forgotPassowrd = false

      setTimeout(() => {
        this.resetBtnState = false
        this.forgotForm = {
          email: ''
        }
      }, 100)
    },
    submitResetForm (evt) {
      evt.preventDefault()
      this.resetBtnState = true
      this.$v.forgotForm.$touch()
      // lee.wilkin@icloud.com

      if (!this.$v.forgotForm.$error) {
        postData('users/userResetPassword', this.forgotForm).then(response => {
          console.log(response.data.message)
          if (response.data && response.data.message) {
            this.resetMessage = response.data.message

          } else {
            this.resetBtnState = false
          }
        })
      }
    },
    //
    returnToLogin () {
      this.cancelResetForm()

      setTimeout(() => {
        this.resetMessage = false
        this.$v.forgotForm.$reset()
      }, 200)
    }
  }
};
</script>
