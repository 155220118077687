import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
import '@fortawesome/fontawesome-free/css/all.css'

const opts = {
  breakpoint: {
    scrollBarWidth: 10,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#1976D2',
        accent: '#c31a7f',
        secondary: '#30b1dc',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
        background: '#eee',
        jigsaw: '#367da2',
        cancelBtn: '#f5f5f5'
      }
    }
  },
  icons: {
    iconfont: 'fa' // 'mdiSvg' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  }
}

export default new Vuetify(opts)
