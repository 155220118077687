<template>
  <div class="text-center">
    <div class="caption line-height-1" v-text="this.date" />
    <div class="caption line-height-1" v-text="this.time" />
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'TimeDisplay',
  data: () => ({
    timer: '',
    datetime: '',
    date: '',
    time: ''
  }),
  created () {
    this.date = moment().format("Do MMMM YYYY")
    this.time = moment().format("h:mma")

    this.timer = setInterval(() => {
      this.date = moment().format("Do MMMM YYYY")
      this.time = moment().format("h:mma")
    }, 6000)
  },
  beforeDestroy() {
    if(this.timer){
      clearInterval(this.timer);
    }
  }
}
</script>
