<template>
  <div  class="jigsaw-login">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="9"
          offset-sm="3"
          md="4"
          offset-md="8"
          class="jigsaw-login__panel"
        >
          <CardFull
            :colour="core.colour.user"
            :icon="core.icon.user"
            iconSpace="7"
            iconSize="medium"
            title="Reset Password"
            :elevation="6"
            :fullWidth="false"
          >
            <v-card-text class="pa-4">
              <v-form
                class="form"
                @submit="submitResetForm"
                @keyup.native.enter="submitResetForm"
              >
                <v-text-field
                  v-model="formData.password"
                  :prepend-inner-icon="core.icon.lock"
                  :append-icon="showNew ? core.icon.view : core.icon.viewOff"
                  :error-messages="passwordErrors"
                  name="password"
                  label="Password"
                  :type="showNew ? 'text' : 'password'"
                  required
                  @input="$v.formData.password.$touch()"
                  @blur="$v.formData.password.$touch()"
                  @click:append="showNew = !showNew"
                />

                <v-text-field
                  v-model="formData.confirm"
                  :prepend-inner-icon="core.icon.lock"
                  :append-icon="confirmNew ? core.icon.view : core.icon.viewOff"
                  :error-messages="confirmErrors"
                  name="confirm"
                  label="Confirm Password"
                  :type="confirmNew ? 'text' : 'password'"
                  required
                  @input="$v.formData.confirm.$touch()"
                  @blur="$v.formData.confirm.$touch()"
                  @click:append="confirmNew = !confirmNew"
                />
              </v-form>
            </v-card-text>
            <v-divider class="mx-4" />
            <v-card-actions class="pa-4">
              <v-btn
                color="primary"
                large
                block
                class="px-4"
                :disabled="loggingIn"
                :loading="loggingIn"
                @click="submitResetForm"
                v-text="'Reset &amp; Login'"
              />
            </v-card-actions>
          </CardFull>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { postDataObj, getData } from '@/utils/api/api'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import CardFull from '@/components/cards/card-full'
export default {
  name: 'ResetPassword',
  components: { CardFull },
  mixins: [validationMixin],

  validations: {
    formData: {
      password: { required },
      confirm: { required, sameAsPassword: sameAs('password') }
    }
  },
  //
  data: () => ({
    core: false,
    key: false,
    formData: {
      password: '',
      confirm: '',
      reset_key: false
    },
    //
    showNew: false,
    confirmNew: false,
    loggingIn: false
  }),
  //
  computed: {
    passwordErrors () {
      const errors = []
      if (!this.$v.formData.password.$dirty) return errors
      !this.$v.formData.password.required && errors.push('Your password is required')
      return errors
    },
    confirmErrors () {
      const errors = []
      if (!this.$v.formData.confirm.$dirty) return errors
      !this.$v.formData.confirm.required && errors.push('A confirm password is required.')
      !this.$v.formData.confirm.sameAsPassword && errors.push('The passwords must match.')
      return errors
    }
  },
  //
  created () {
    this.$store.watch(
      (state, getters) => getters.getCore, (result) => {
        this.core = result
      }, {
        immediate: true
      }
    )

    this.formData.reset_key = this.$route.params.key
  },
  //
  methods: {
    submitResetForm () {
      this.$v.formData.$touch()

      if (!this.$v.formData.$error) {
        this.loggingIn = true
        postDataObj('users/userResetLogin', this.formData).then(response => {
          if (response.data && response.data.user) {
            this.$store.commit('authUser', response.data.user)
            this.getAccount()
            this.loggingIn = false

            if (this.$route.path !== '/dashboard') {
              this.$router.push('/dashboard')
            }
          }
        })
      }
    },
    getAccount () {
      getData('getUserAccount').then(response => {
        if (response.data) {
          this.$store.commit('setAccount', response.data)
        }
      })
    }
  }
}
</script>
