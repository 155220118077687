import Vue from "vue"
import Vuex from "vuex"
import { getData } from '@/utils/api/api'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // USERS
    user: {},
    userBase: {
      messages: [],
      tickets: [],
      requests: [],
      quotes: [],
      invoices: []
    },

    core: {
      icon: {
        account: 'mdi-account-group-outline', // mdi-account-group-outline mdi-account-outline
        comment: 'mdi-comment-outline',
        dashboard: 'mdi-compass-outline',
        invoice: 'mdi-file-document-outline',
        message: 'mdi-email-outline',
        messages: 'mdi-email-outline',
        profile: 'mdi-account-circle-outline',
        quote: 'mdi-file-code-outline',
        request: 'mdi-code-json',
        site: 'fas fa-globe',
        support: 'mdi-toolbox-outline',
        task: 'mdi-list-status',
        ticket: 'mdi-ticket-confirmation-outline', // mdi-rotate-90
        //
        remoteAccess: 'mdi-network-outline',
        //
        user: 'mdi-account-outline',
        users: 'mdi-account-multiple-outline',
        search: 'fas fa-search',
        // Mail
        inbox: 'mdi-inbox-outline',
        sent: 'mdi-email-send-outline',
        archive: 'mdi-archive-outline',
        reply: 'mdi-reply-outline',
        // SYSTEM
        system: 'mdi-tools',
        email: 'mdi-email-outline',
        tag: 'mdi-tag-outline',
        tags: 'mdi-tag-multiple-outline',
        userTag: 'fas fa-user-tag', //mdi-tag-text-outline
        type: 'mdi-filter-outline',
        mapPin: 'mdi-map-marker',
        messageAdd: 'mdi-email-plus-outline',
        messageSearch: 'mdi-email-search-outline',
        //
        address: 'mdi-map-marker',
        alert: 'mdi-alert-circle-outline',
        arrowRight: 'mdi-arrow-right',
        calendar: 'mdi-calendar-outline',
        chevronUp: 'mdi-chevron-up',
        chevronLeft: 'mdi-chevron-left',
        chevronRight: 'mdi-chevron-right',
        clock: 'mdi-clock-outline',
        close: 'mdi-close',
        copy: 'mdi-content-copy',
        circle: 'mdi-circle',
        delete: 'mdi-trash-can-outline',
        download: 'mdi-download',
        edit: 'mdi-pencil-outline',
        externalLink: 'mdi-open-in-new',
        lock: 'mdi-lock-outline',
        longArrowRight: 'mdi-arrow-right-thin',
        menuDown: 'mdi-menu-down',
        phone: 'mdi-phone-outline',
        rotateLS: 'mdi-phone-rotate-landscape',
        status: 'mdi-progress-wrench',
        swatch: 'mdi-palette-swatch-outline',
        sync: 'mdi-sync',
        undo: 'mdi-undo-variant',
        upload: 'mdi-upload-outline',
        uploadCloud: 'mdi-cloud-upload-outline',
        tick: 'mdi-check',
        view: 'mdi-eye-outline',
        viewOff: 'mdi-eye-off-outline',
        // APOLLO
        apollo: 'mdi-rocket-outline',
        apolloAPI: 'mdi-api',
        apolloModule: 'mdi-cog-sync-outline',
        apolloHelp: 'mdi-message-cog-outline',
        apolloHelpOff: 'mdi-message-off-outline'
      },
      iconAlt: {
        account: 'fas fa-folder-open',
        dashboard: 'fas fa-tachometer-alt',
        invoice: 'mdi-file-document-outline',
        messages: 'mdi-email-open-outline',
        profile: 'fas fa-user',
        quote: 'mdi-file-code-outline',
        request: 'mdi-code-json',
        site: 'fas fa-globe',
        support: 'mdi-toolbox-outline',
        task: 'mdi-list-status',
        ticket: 'mdi-ticket-confirmation-outline',
        // SYSTEM
        system: 'mdi-tools',
        tag: 'mdi-tag-multiple-outline',
        type: 'mdi-filter-off-outline',
      },
      colour: {
        account: 'teal',
        comment: 'cyan',
        dashboard: 'secondary',
        invoice: 'green',
        profile: 'teal',
        message: 'blue',
        messages: 'blue',
        quote: 'amber darken-4',
        quoteText: 'amber--text text--darken-4',
        request: 'purple',
        site: 'teal',
        support: 'cyan darken-1',
        task: 'orange',
        ticket: 'indigo',
        user: 'teal',
        // SYSTEM
        system: 'indigo darken-4',
        type: 'indigo darken-2',
        tag: 'indigo',
        email: 'blue',
        jigsaw: 'jigsaw',
        //
        apollo: 'blue-grey'
      }
    },
    //
    drawer: true,
    headerTitle: '',
    breadcrumbs: [],
  },
  getters: {
    getCore: state => state.core,
    getNavDrawer: state => state.drawer,
    getHeaderTitle: state => state.headerTitle,
    getBreadcrumbs: state => state.breadcrumbs,
    getUser: state => state.user,
    getUserBase: state => state.userBase,
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setUserBase (state, userBase) {
      state.userBase = userBase
    },
    setHeaderTitle (state, headerTitle) {
      state.headerTitle = headerTitle
    },
    setNavDrawer (state, drawer) {
      state.drawer = drawer
    },
    setBreadcrumbs (state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },
    setUserMessages (state, messages) {
      state.userBase.messages = messages
    },
    updateBase (state, action) {
      if (action) {
        getData('users/getUserBase').then(response => {
          if (response.data) {
            if (response.data.type && response.data.type !== '') {
              state.userBase = response.data
            }
          }
        })
      }
    }
  },
  actions: {},
  modules: {},
});
